$('.project').click(function () {
    $(this).parent().find(".description-project").slideToggle( "slow");
    $(this).parent().find(".title-project").find(".plus").toggleClass( "minus");
});

$('.a')

$('.sidebar-toggle').click(function () {
    $('.mob-sidebar').toggle("slide")
});
$('.mob-link').click(function () {
    $('.mob-sidebar').hide("slide")
});


$(function(){
    var current = location.href;
    $('#new-cognito-links a').each(function(){
        var $this = $(this);
        // if the current path is like this link, make it active
        console.log('attr', current, $this.attr('href').indexOf(current));
        if ($this.attr('href') === current) {
            $this.addClass('active-link');
        }

        if (location.href.indexOf('area-work') >= 0) {
            $('#area-works').addClass('active-link');
        }
    })
});


function emailSend() {
    $('textarea').val('');
    $('input').val('');

    swal({
        position: 'center',
        type: 'success',
        title: 'Your email has been sent',
        showConfirmButton: false,
        timer: 1500
    })
}
